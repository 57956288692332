var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
let ShortLinkDownloadList = class ShortLinkDownloadList extends Vue {
    constructor() {
        super(...arguments);
        this.code = this.$route.params.code;
        this.urlToDownloadList = '';
        this.hostname = window.location.hostname;
    }
    async created() {
        await this.getUrlToDownloadList();
    }
    async getUrlToDownloadList() {
        const result = await Vue.axios.get(`/link_shortener/redirect/${this.$route.params.code}`);
        this.urlToDownloadList = result.data.value;
        this.downloadList();
    }
    downloadList() {
        const link = document.createElement('a');
        link.href = this.handleHostnameUrlBasedInEnvironment();
        link.click();
        setTimeout(() => {
            window.location.href = '/search';
        }, 2000);
    }
    handleHostnameUrlBasedInEnvironment() {
        return this.hostname === 'localhost'
            ? `http://localhost:8080/${this.urlToDownloadList.split('.com.br/')[1]}`
            : this.urlToDownloadList;
    }
};
ShortLinkDownloadList = __decorate([
    Component({
        name: 'ShortLinkDownloadList',
    })
], ShortLinkDownloadList);
export default ShortLinkDownloadList;
